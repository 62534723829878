<template>
  <b-modal id="files-prepare-modal" size="sm" hide-header hide-footer centered>
    <div class="header d-flex flex-row justify-content-between">
      <p class="title">Preparando documentos</p>
      <v-close class="close" @click="$bvModal.hide('files-prepare-modal')" />
    </div>
    <div class="body">
      {{JSON.stringify(toPrint)}}
      <div @click="prepareFiles">{{ attendanceId }}</div>
      <div>Preparando documentos para impressão</div>
    </div>
  </b-modal>
</template>
<script>
import * as PDFLib from 'pdf-lib'
import Close from '@/assets/icons/close.svg'
export default {
  name: 'file-prepared-modal',
  components: {
    'v-close': Close,
  },
  props: {
    attendanceId: String,
    toPrint: Array,
  },
  mounted() {
    this.prepareFiles();
  },
  methods: {
    prepareFiles: async function() {
      if (!this.attendanceId || !this.toPrint.length) return;
      try {
        const groupedFiles = [];
        const attendanceFiles = await this.getAttendanceFiles(true);

        for (let i = 0; i < attendanceFiles.length; i++) {
          const attendanceFile = attendanceFiles[i];
          if(attendanceFile.type_id === '9') {
            const merged = await this.mergeAllPDFs([attendanceFile, attendanceFile]);
            this.openDocument(encodeURI(merged));
          } else if (this.toPrint.includes(parseInt(attendanceFile.type_id))) {
            groupedFiles.push(attendanceFile);
          }
        }

        if (groupedFiles.length) {
          const url = await this.mergeAllPDFs(groupedFiles);
          this.openDocument(encodeURI(url));
        }

        this.$bvModal.hide('files-prepare-modal');
        this.$bvModal.show('success-appointment-modal');
      } catch (e) {
        console.log('*** error prepare files **** ', e)
      }
    },
    getAttendanceFiles(loadTemporaryUrl) {
      return new Promise((resolve, reject) => {
        this.api.getAttendanceFiles(this.attendanceId, loadTemporaryUrl)
        .then(res => resolve(res.data))
        .catch(reject)
      });
    },
    openDocument(url){
        var w = window.open('about:blank');
        w.document.write(`
          <html>
            <body style="margin:0;">
              <iframe width="100%" height="100%" src="${url}" style="border:none" >
            </body>
          </html>
        `);
    },
    getDocumentFile(path) {
      return new Promise((resolve, reject) => {
        this.api.getDocumentFile(path)
        .then(res => resolve(res.data))
        .catch(reject);
      })
    },
    async mergeAllPDFs(attendanceFiles) {
        const pdfDoc = await PDFLib.PDFDocument.create();
        for(var i = 0; i < attendanceFiles.length; i++) {
            const file = await this.getDocumentFile(attendanceFiles[i].id);
            const blob = new Blob([file], { type: 'application/pdf' })
            const donorPdfBytes = await blob.arrayBuffer()
            const donorPdfDoc = await PDFLib.PDFDocument.load(donorPdfBytes);
            const docLength = donorPdfDoc.getPageCount();
            for(var k = 0; k < docLength; k++) {
                const [donorPage] = await pdfDoc.copyPages(donorPdfDoc, [k]);
                pdfDoc.addPage(donorPage);
            }
        }
        return await pdfDoc.saveAsBase64({ dataUri: true });
    },
  },
}
</script>
<style lang="scss">
#files-prepare-modal {
  font-family: 'Nunito Sans';
  .modal-body {
    padding: 0 !important;
    .header {
      padding: 24px;
      border-bottom: 1px solid var(--neutral-200);
      .title {
        font-weight: 600;
        font-size: 18px;
        color: var(--type-active);
        margin: 0;
      }
      .close {
        width: 24px !important;
        height: 24px !important;
        fill: black;
        cursor: pointer;
      }
    }

    .body {
      padding: 24px;
    }
  }
}
</style>
