<template>
  <tr id="attendance-review">
    <td class="tdLabel">{{ label }}</td>
    <td v-if="attendanceType == 1" align="center">
      <label :id="`print_${identifier}`" class="checkbox" align="center">
        <input
          type="checkbox"
          v-model="attendanceReviewCopy[identifier]"
          :disabled="!Boolean(attendanceReview[identifier])"
        />

        <b-tooltip v-if="!attendanceReview[identifier]" :target="`print_${identifier}`" triggers="hover" placement="bottom">
          Para imprimir, assinar ou enviar este documento, preencha o campo dentro da consulta.
        </b-tooltip>

        <span class="checkmark" :class="{ disabled: !attendanceReview[identifier] }">
          <Check />
        </span>
      </label>
    </td>

    <td align="center">
      <label :id="`signature_${identifier}`" class="checkbox" align="center">
        <input
          type="checkbox"
          v-model="attendanceSignatureItemCopy[identifier]"
          :disabled="!Boolean(attendanceReview[identifier])"
        />

        <b-tooltip v-if="!attendanceReview[identifier]" :target="`signature_${identifier}`" triggers="hover" placement="bottom">
          Para imprimir, assinar ou enviar este documento, preencha o campo dentro da consulta.
        </b-tooltip>

        <span class="checkmark" :class="{ disabled: !attendanceReview[identifier] }">
          <Check />
        </span>
      </label>
    </td>
  </tr>
</template>

<script>
import Check from '../../assets/icons/check.svg'

export default {
  props: {
    label: String,
    identifier: String,
    attendanceType: Number,
    attendanceReview: Object,
    attendanceReviewCopy: Object,
    attendanceSignatureItem: Object,
    attendanceSignatureItemCopy: Object,
  },
  components: {
    Check
  },
}
</script>

<style lang="scss">
#attendance-review {
  .tdLabel {
    font-size: 16px;
    color: var(--type-active);
    font-weight: 400;
    padding: 10px 0px;
  }
  .checkbox {
    margin-top: -12px;
    display: initial !important;
    .disabled {
      background-color: #D9DFF2;
      border: none;
      svg {
        display: none;
      }
    }
  }
  .checkbox.bold {
    font-weight: 700;
    margin-bottom: 16px;
  }
  .tooltip-inner {
    width: 256px;
  }
}
</style>


